<template>
  <v-card flat outlined class="rounded-10 campaign-settings" min-height="100vh">
    <v-card-title
      class="primary--text d-flex align-center justify-space-between fw-700"
    >
      <span class="ml-2">Emails Settings</span>
      <v-btn @click="goBack" icon>
        <v-icon large color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-sheet class="secondary--text body pa-5">
        In order to create email campaigns. You need first to setup your mailer
        account integration.
      </v-sheet>
      <v-row dense align="stretch" v-if="!loading_items">
        <v-col
          cols="12"
          v-for="each in items"
          :key="each.id"
          md="6"
          sm="12"
          class="pa-2"
        >
          <EachMailer
            :item="each"
            :mailers="mailers"
            @delete="handleDelete(each)"
            @edit="action_add_edit_dialog(true, each)"
          ></EachMailer>
        </v-col>
        <v-col cols="12" md="6" sm="12" class="pa-2">
          <v-card
            class="rounded-10 d-flex align-center justify-center fill-height"
            elevation="5"
            min-height="400"
            @click="action_add_edit_dialog(true, null)"
          >
            <div class="d-flex flex-column pa-5 align-center justify-center">
              <v-icon color="primary" size="100">mdi-plus</v-icon>
              <span class="fw-900 fs-16 primary--text">Add New</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <PageLoader min-height="80vh" v-if="loading_items"></PageLoader>
    </v-card-text>
    <v-card-actions>
      <v-sheet class="fullwidth d-flex flex-column" flat>
        <label class="primary--text fw-700 ml-md-5">
          Below are some supported SMTP mailers
        </label>
        <v-slide-group class="pa-4 mx-auto" active-class="success" show-arrows>
          <v-slide-item v-for="mailer in mailers" :key="mailer.name">
            <v-hover v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 5 : 1"
                @click="navigateExternal(mailer)"
                class="ma-4 rounded-10 d-flex align-center justify-center"
                height="100"
                width="160"
              >
                <v-img :src="mailer.logo" contain></v-img>
              </v-card>
            </v-hover>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-card-actions>
    <MailerConfigModal
      v-model="add_edit_dialog"
      :item="activeItem"
      :mailers="mailers"
      @save="handleSave"
      @update="handleUpdate"
    ></MailerConfigModal>
  </v-card>
</template>

<script>
import EachMailer from '../components/EachMailer.vue'
import MailerConfigModal from '../components/MailerConfigModal.vue'
import request from '@/services/axios_instance'
import { fetch_services } from '@/services/fetch/fetch_services'

export default {
  components: { EachMailer, MailerConfigModal },
  name: 'SetupTwilio',
  mixins: [fetch_services],
  data: () => ({
    mailers: [
      {
        link: 'https://www.twilio.com/sendgrid/email-api',
        name: 'Twilio SendGrid Email',
        logo: require('@/assets/mailers/sendgrid.svg').default,
        value: 'sendgrid'
      },
      {
        link: 'https://signup.mailgun.com/',
        name: 'Mailgun',
        logo: require('@/assets/mailers/mailgun.png'),
        value: 'mailgun'
      },
      {
        link: 'https://mailchimp.com',
        name: 'MailChimp',
        logo: require('@/assets/mailers/mailchimp.png'),
        value: 'mailchimp'
      },
      {
        link: 'https://postmarkapp.com/',
        name: 'Postmark',
        logo: require('@/assets/mailers/postmark.svg').default,
        value: 'postmark'
      },
      {
        link: 'https://www.smtp2go.com/pricing/',
        name: 'SMTP2GO',
        logo: require('@/assets/mailers/smtp2go.svg').default,
        value: 'smtp2go'
      },
      {
        link: 'https://mailtrap.io/register/signup',
        name: 'Mailtrap',
        logo: require('@/assets/mailers/mailtrap.png'),
        value: 'mailtrap'
      }
    ]
  }),
  created() {
    this.loadItems(`api/mailers`, false, null, null)
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'campaigns', params: { type: 'email' } })
    },
    handleDelete(item) {
      this.appConfirmation(
        'Delete this configuration?',
        () => {
          this.deleteItem(`api/mailers/${item.id}`, 'Configuration deleted')
        },
        null,
        'Yes'
      )
    },
    handleUpdate(payload) {
      this.updateItem(
        `api/mailers/${this.activeItem.id}`,
        payload,
        'Configuration updated.',
        (data) => {
          this.action_add_edit_dialog(false, data)
        }
      )
    },
    handleSave(payload) {
      this.addItem(`api/mailers`, payload, 'Configuration added.', () => {
        this.action_add_edit_dialog(false, null)
      })
    },
    navigateExternal(mailer) {
      window.open(mailer.link, '_blank')
    }
  }
}
</script>

<style></style>
