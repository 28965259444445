<template>
  <CustomDialog
    v-model="open"
    :title="isEdit ? 'Update Configuration' : 'New Configuration'"
    @button2="save"
    :main-btn-disabled="!valid"
    :button2-text="isEdit ? 'Update' : 'Save'"
  >
    <template v-slot:content>
      <v-card flat>
        <v-img
          height="50"
          v-if="selectedMailer"
          contain
          :src="selectedMailer.logo"
        ></v-img>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col cols="12" md="6" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text"> Name * </label>
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    v-model="name"
                    :rules="[requiredRule()]"
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text"> Mailer * </label>
                  <v-select
                    outlined
                    dense
                    item-text="name"
                    item-value="value"
                    :items="mailers"
                    hide-details="auto"
                    v-model="source"
                    :rules="[requiredRule()]"
                  >
                  </v-select>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text"> Host * </label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[requiredRule()]"
                    hide-details="auto"
                    v-model="host"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text"> Port * </label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[requiredRule()]"
                    hide-details="auto"
                    v-model="port"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text"> Encryption * </label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[requiredRule()]"
                    hide-details="auto"
                    v-model="encryption"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text"> Username * </label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[requiredRule()]"
                    hide-details="auto"
                    v-model="username"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text"> Password * </label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[requiredRule()]"
                    hide-details="auto"
                    v-model="password"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text">
                    From Email Address
                  </label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[emailRule(), requiredRule()]"
                    hide-details="auto"
                    v-model="from_address"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text"> From Name </label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[requiredRule()]"
                    hide-details="auto"
                    v-model="from_name"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text">
                    Reply To Email Address
                  </label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[emailRule(), requiredRule()]"
                    hide-details="auto"
                    v-model="reply_to_address"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text">
                    Reply To Name
                  </label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[requiredRule()]"
                    hide-details="auto"
                    v-model="reply_to_name"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-form>
          <small class="error--text fw-700">* Required</small>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  components: { CustomDialog },
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object },
    mailers: Array
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val
        if (val && !this.isEdit) {
          this.resetValidation()
          this.name = null
          this.source = 'mailgun'
          this.host = null
          this.port = null
          this.encryption = 'TLS'
          this.username = null
          this.password = null
          this.from_address = null
          this.from_name = null
          this.reply_to_address = null
          this.reply_to_name = null
        }
      },
      immediate: true
    },
    open: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    },
    item: {
      handler: function (val) {
        this.name = val ? val.name : null
        this.source = val ? val.source : 'mailgun'
        this.host = val ? val.host : null
        this.port = val ? val.port : null
        this.encryption = val ? val.encryption : 'TLS'
        this.username = val ? val.username : null
        this.password = val ? val.password : null
        this.from_address = val ? val.from_address : null
        this.from_name = val ? val.from_name : null
        this.reply_to_address = val ? val.reply_to_address : null
        this.reply_to_name = val ? val.reply_to_name : null
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    isEdit() {
      return this.item ? true : false
    },
    selectedMailer() {
      return this.mailers.find((i) => i.value === this.source)
    },
    payload() {
      return {
        id: this.isEdit ? this.item.id : null,
        name: this.name,
        source: this.source,
        host: this.host,
        port: this.port,
        encryption: this.encryption,
        username: this.username,
        password: this.password,
        from_address: this.from_address,
        from_name: this.from_name,
        reply_to_address: this.reply_to_address,
        reply_to_name: this.reply_to_name
      }
    }
  },
  data: () => ({
    valid: false,
    open: false,
    name: null,
    source: null,
    host: null,
    port: null,
    encryption: null,
    username: null,
    password: null,
    from_address: null,
    from_name: null,
    reply_to_address: null,
    reply_to_name: null
  }),
  methods: {
    save() {
      this.$emit(this.isEdit ? 'update' : 'save', this.payload)
    }
  }
}
</script>
