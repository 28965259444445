<template>
  <v-card v-bind="$attrs" class="rounded-10" elevation="5">
    <v-card-title class="d-flex align-center justify-space-between">
      <strong class="primary--text">{{ item.name | ucwords }}</strong>
      <div>
        <HelpToolTip
          @click:icon="$emit('delete')"
          icon="mdi-delete"
          icon-color="error"
          text="Delete configuration"
        ></HelpToolTip>
        <HelpToolTip
          @click:icon="$emit('edit')"
          icon="mdi-pencil"
          text="Edit configuration"
        ></HelpToolTip>
      </div>
    </v-card-title>
    <v-card-text class="px-7">
      <v-row dense>
        <v-col cols="12" md="6" sm="12" class="order-sm-2 order-md-1">
          <div class="mb-2">
            <label class="subtitle-2 primary--text"> Mailer Source* </label>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.source"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" sm="12" class="order-sm-1 order-md-2">
          <v-img
            height="50"
            v-if="selectedMailer"
            contain
            :src="selectedMailer.logo"
          ></v-img>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6" sm="12">
          <div class="mb-2">
            <label class="subtitle-2 primary--text"> Host </label>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.host"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <div class="mb-2">
            <label class="subtitle-2 primary--text"> Port </label>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.port"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <div class="mb-2">
            <label class="subtitle-2 primary--text"> Encryption </label>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.encryption"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6" sm="12">
          <div class="mb-2">
            <label class="subtitle-2 primary--text"> Username </label>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.username"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <div class="mb-2">
            <label class="subtitle-2 primary--text"> Password </label>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.password"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6" sm="12">
          <div class="mb-2">
            <label class="subtitle-2 primary--text"> From Email Address </label>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.from_address"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <div class="mb-2">
            <label class="subtitle-2 primary--text"> From Name </label>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.from_name"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6" sm="12">
          <div class="mb-2">
            <label class="subtitle-2 primary--text">
              Reply To Email Address
            </label>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.reply_to_address"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <div class="mb-2">
            <label class="subtitle-2 primary--text"> Reply To Name </label>
            <v-text-field
              outlined
              dense
              readonly
              hide-details="auto"
              v-model="item.reply_to_name"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex pa-4 align-center justify-end">
      <v-btn
        @click="open_test = true"
        class="text-none px-5"
        color="primary"
        small
      >
        Send Test Email
      </v-btn>
    </v-card-actions>
    <MailerTestModal :item="item" v-model="open_test"></MailerTestModal>
  </v-card>
</template>
<script>
import MailerTestModal from './MailerTestModal.vue'
export default {
  components: { MailerTestModal },
  inheritAttrs: false,
  props: {
    item: { type: Object, required: true },
    mailers: Array
  },
  data: () => ({
    open_test: false
  }),
  computed: {
    selectedMailer() {
      return this.mailers.find((i) => i.value === this.item.source)
    }
  }
}
</script>
