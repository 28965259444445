<template>
  <CustomDialog
    v-model="open"
    title="Test Send Email"
    @button2="send"
    :main-btn-disabled="!valid || sending"
    button2-text="Send"
    button1-text="Close"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text"> Send To </label>
                  <v-text-field
                    outlined
                    dense
                    autofocus
                    hide-details="auto"
                    v-model="destination_email"
                    :rules="[requiredRule(), emailRule()]"
                  >
                  </v-text-field>
                </div>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <div class="mb-2">
                  <label class="subtitle-2 primary--text"> Message Body </label>
                  <v-textarea
                    outlined
                    dense
                    hide-details="auto"
                    v-model="message_body"
                    rows="3"
                  ></v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import request from '@/services/axios_instance'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
export default {
  components: { CustomDialog },
  inheritAttrs: false,
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object, required: true }
  },
  watch: {
    value: {
      handler: function (val) {
        this.open = val
        if (val) {
          this.resetValidation()
          this.destination_email = null
          this.message_body = null
        }
      },
      immediate: true
    },
    open: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    }
  },
  computed: {
    payload() {
      return {
        mailer_id: this.item.id,
        message: this.message_body,
        destination_email: this.destination_email
      }
    }
  },
  data: () => ({
    valid: false,
    open: false,
    sending: false,
    destination_email: null,
    message_body: null
  }),
  methods: {
    send() {
      request
        .post(`api/mailers/send-test`, this.payload)
        .then(({ data }) => {
          this.appSnackbar(data.message, data.type)
        })
        .finally(() => {
          this.$event.$emit('btnloading_off')
        })
    }
  }
}
</script>
